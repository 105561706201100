/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white
  }
  
  .header .logo a {
    color: white;
    text-decoration: none;
    font-size: 1.5em;
  }
  
  .header .navigation ul {
    list-style: none;
    display: flex;
  }
  
  .header .navigation li {
    margin-left: 20px;
  }
  
  .header .navigation li a {
    color: white;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    .collapsed + .header{
      margin-left: 70px;  /* Collapsed width for mobile */
    }
  }
  @media (min-width: 768px) {
    .collapsed + .header{
      margin-left: 100px;  /* Collapsed width for desktop */
    }
  }
  .expanded + .header{
    margin-left: 250px;
  }
  .search{
    font-size: 15.5px!important;
    background-color: #eeeeee7a;
  }
  .icon-button{
    --button-size: calc(var(--nav-size) * 0.7);
    width: var(--button-size);
    height: var(--button-size);
    /* padding-top: 5px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 10px; */
    background-color: transparent;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: filter 500ms;
    display: 'relative';
}
.icon-button:hover{
 /* background-color: #3cde3cde; */
}
.svg svg{
  height: auto;
  color: #eeeeee;
  width: 21px;
}
.icon-button svg{
    height: 30px;
}
.menu-item {
  width: auto;
  text-decoration: none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 15px 20px;
  border: 1px solid #ddd;
  background-image: linear-gradient(to left bottom, #f0f0f0, #ffffff) !important;
  color: #363636;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  font-family: sans-serif;
  letter-spacing: 0.3px;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.menu-item.active-tab {
  color: white;
  background: #4a4a4a !important;
}
@media (max-width: 575.98px) { /* Bootstrap's small breakpoint */
  .menu-item {
    width: 50%;
    padding: 10px 20px;
  }
}

.textarea {
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.app-form {
  background-color: rgb(248 248 248);
}
.svg svg{
  height: auto;
  color: #eeeeee;
  width: 21px;
}
.icon-button svg{
    height: 30px;
}

.icon-button{
  --button-size: calc(var(--nav-size) * 0.7);
  width: var(--button-size);
  height: var(--button-size);
  /* padding-top: 5px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 10px; */
  background-color: transparent;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: filter 500ms;
  display: 'relative';
}
.h-bg{
  background: #aeb4b582;
}