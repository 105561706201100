@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700;900&display=swap');

html, 
body {
    height: 100%;
    background:whitesmoke  !important;
    font-family: 'anton', serif;
}
.app-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
main {
  flex-grow: 1;
}
body>#root>div {
  height: 100vh;
}
.swal-button{
  width: 100%;
}
.font-monst{
  font-family: 'Montserrat';
  font-weight: 500;
}
.custom-font4 {
  font-family: Cinzel,serif;
}
.font-f-roboto {
  font-family: 'Roboto', sans-serif;
}
body {
  /* background: #f4f5f8 !important; */
  margin: 0;
  height: 100vh;
  color: #353535;
  min-height: 100vh;
  /* font-family: roboto; */
  padding: 0;
}

#root {
  /* overflow: auto; */
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
  height: 100%;
}
.row {
  --bs-gutter-x: 0 !important;
}

.row>* {
  --bs-gutter-x: 0 !important;
}
.size-16{
  font-size: 16px;
}
.size-17{
  font-size: 17px;
}
.size-18{
  font-size: 18px;
}
.size-20{
  font-size: 20px;
}
.size-22{
  font-size: 22px;
}
.bold{
  font-weight: bold;
}
.white{
  color:white !important;
}
.acc-form {
  background-color: #eff0ff!important;
}
.w-90 {
  width: 90% !important;
}
.w-30 {
  width: 30% !important;
}
.w-80 {
  width: 90% !important;
}
.w-10 {
  width: 10% !important;
}
.w-5 {
  width: 5% !important;
}
.w-20 {
  width: 20% !important;
}
.app-form {
  background-color: rgb(248 248 248);
}
.textarea {
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 992px) {
  .w-lg-25 {
      width: 25%;
  }
  .w-lg-75 {
    width: 25%;
  }
  .w-lg-90 {
    width: 90% !important;
  }
}
.footer {
  color: white;
}

.footer .f-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background:#aeb4b5;
}
.btn {
  font-family: 'Fira Sans';
  font-weight: 500 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), 
              transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
              box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}
.btn-info {
  color: white !important;
}

.btn:hover {
  transform: scale(1.05); /* Slightly enlarge the button */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
.back-text {
  padding: 5px;
  font-family: 'Cinzel';
  font-weight: 600;
}
.back-icon {
  color: #343d47;
  
}

.back-icon:hover {
  text-decoration: underline !important;
  color: rgb(10, 10, 172) !important;
  cursor: pointer;
}
.font-serif {
  font-family: serif;
}
.form-control, .form-select {
  border: 1px solid #ced4da; /* Default border color */
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:hover, .form-select:hover {
  border-color: #80bdff; /* Slightly lighter blue on hover */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25); /* Light blue shadow */
}

.form-control:focus, .form-select:focus {
  border-color: #80bdff; /* Match hover color on focus */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25); /* Match shadow on focus */
  outline: none; /* Remove default outline */
}
.date-input {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  padding: 5px 15px;
  border: 0;
  border-color: initial;
  border-image: none;
  border-image: initial;
  border-radius: 4px;
}
.table .thead-dark th {
  vertical-align: middle;
}
.p-bg{
  background: #2490a5;
  color: white;
}
.font-f-sans{
  font-family: sans-serif;
}
.form-control:disabled{
  color: #6c757d;
  border-color: #ced4da;
}
.form-control.disabled:hover,
.form-control.disabled:focus,
.form-control.disabled:active {
    border-color: #ced4da; /* Same as the default border color */
    background-color: #e9ecef; /* Ensure background remains consistent */
    outline: none; /* Remove outline */
    box-shadow: none; /* Remove any shadow effect */
    border: none;
}
th, td {
  vertical-align: middle; 
}
tr{
  font-family: 'Roboto';
}
thead{
  font-size: 17px;
}
.area {
  background: #fff;
  border: 1px solid #70707066;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}
.nowrap{
  white-space: nowrap;
}
@font-face {
  font-family: 'Jameel Noori Nastaleeq';
  src:('/jameel-noori-nastaleeq-regular/JameelNooriNastaleeq.ttf')format('truetype');
}

.urdu-text {
  font-family: 'Jameel Noori Nastaleeq', Arial, sans-serif;
}
@font-face {
  font-family: 'JameelNooriNastaleeq';
  src: url('../JameelNooriNastaleeqRegular.ttf') format('truetype');
}