
.error {
    color: red;
    margin: 10px 0;
  }
  .user-page{
    display: flex;
    justify-content: center; /* Center horizontally */
    background: #0a666a; /* Background gradient */
    position: relative; /* Needed for the ::before pseudo-element */
    overflow: hidden; /* Ensure blur effect stays within the boundaries */
    height: 100vh; /* Full height of the viewport */
  }
  .user-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit; /* Inherit the background gradient */
    filter: blur(50px); /* Apply the blur effect */
    z-index: -1; /* Ensure the blur is behind the content */
}
.container {
    background: #2c2e49; /* Dark background similar to the form box in the image */
    border-radius: 10px;
    box-shadow:0 10px 20px rgb(255 255 255 / 30%); /* Shadow for a slight elevation effect */
    position: relative;
    overflow: hidden;
}


h2 {
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
}

form {
    display: flex;
    flex-direction: column;
}

input {
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent input background */
    border: none;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    outline: none;
}

input::placeholder {
    color: rgba(255, 255, 255, 0.7); /* Placeholder text color */
}

.button-group {
    display: flex;
    justify-content: space-between;
}

button {
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: background 0.3s;
}

.register-btn {
    background: #5370f5; /* Blueish button similar to the Register button in the image */
    color: #fff;
}
.acc-label{
    font-family: 'Poppins', sans-serif;
}

.reset-btn {
    background: #43d8a3; /* Greenish button similar to the Reset button in the image */
    color: #fff;
}

button:hover {
    opacity: 0.9;
}
.text-white{
    color: white;
}
input[type="checkbox"] {
    width: 12px; /* Set the base size */
    height: 12px;
    transform: scale(1.5); /* Scale it up */
    -webkit-transform: scale(1.5);
    margin-right: 10px;
}
.size-18{
    font-size: 18px;
}
.text-center {
    text-align: center;
  }
  
  .text-left {
    text-align: left;
  }
  
  .text-right {
    text-align: right;
  }
.form-box{
    margin: 0 auto;
}
/* Responsive adjustments */
@media (min-width: 768px) {
    /* For tablets */
    .container {
      width: 70%; /* Adjust the width for tablet devices */
    }
  }
@media (min-width: 1024px) {
    /* For desktops */
    .user-page{
        align-items: center;
    }
    .container {
      width: 600px; /* Further adjust the width for desktop devices */
    }
}
@media (max-width: 320px) {
    .container {
        width: 95%; /* Adjust for very small screens */
        padding: 15px;
    }
}
/* Target small screens (e.g., most smartphones) */
@media (max-width: 480px) {
    .container {
        width: 90%; /* Adjust width for small screens */
    }
    .btn {
        padding: 7px 12px !important;
      }
}
/* Target medium screens (e.g., large smartphones, small tablets) */
@media (max-width: 768px) {
    .btn {
        padding: 7px 12px !important;
      }
    .container {
        width: 100%; /* Adjust width for medium screens */
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .container {
        width: 100%; /* Adjust width for tablets */
    }
}
.s-bg{
    background: #2490a5;
}
.form-group {
    display: flex;
    flex-direction: column; /* Label and input arranged in a column */
    gap: 5px; /* Space between label and input */
}

